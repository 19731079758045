import { LOCALES } from "../locales";

const daDK = {
  [LOCALES.DANISH]: {
    header: "Kontakt Mig",
    description1: "Udfyld nedstående formular med dit spørgsmål,",
    description2: "og jeg skal prøve at vende tilbage hurtigst muligt.",
    firstname: "Fornavn *",
    lastname: "Efternavn *",
    email: "Email *",
    phone: "Telefonnummer",
    message: "Besked *",
    yourMessage: "Din besked",
    sendMessage: "Send besked",
    required: "Disse felter skal udfyldes.",
  },
};

export default daDK;
