import React, { Fragment, FC } from "react";
import { IntlProvider } from "react-intl";

import { LOCALES } from "./locales";
import Messages from "./messages";

interface Props {
  children: React.ReactNode;
  locale: string;
}

const Provider: FC<Props> = ({ children, locale = LOCALES.DANISH }) => (
  <IntlProvider
    locale={locale}
    textComponent={Fragment}
    messages={Messages[locale]}
  >
    {children}
  </IntlProvider>
);

export default Provider;
