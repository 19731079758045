import { useRef, useState, useCallback } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";

import Captcha from "../../components/Captcha/Captcha";

import styles from "./Contact.module.css";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export default function Contact() {
  const [validated, setValidated] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const firstnameRef = useRef<HTMLInputElement>(null);
  const lastnameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  // This resets the values in the form
  const resetForm = () => {
    // Reset form
    formRef.current!.reset();
    // Change state, and trigger component rerender
    setValidated(false);
  };

  // This creates a form data object containing our input values
  const createFormData = () => {
    // Get reCaptcha response
    let response: string = window.grecaptcha.getResponse();

    // Reset reCaptcha
    window.grecaptcha.reset();

    // Check response
    if (!(response.length > 0)) {
      toast.warning("Captcha er ikke udført.", {
        draggable: false,
        theme: "dark",
        icon: true,
      });
    } else {
      // Create FormData object
      let data = new FormData();

      // Append values
      data.append("g-recaptcha-response", response);
      data.append("firstname", firstnameRef.current!.value);
      data.append("lastname", lastnameRef.current!.value);
      data.append("email", emailRef.current!.value);
      data.append("phone", phoneRef.current!.value);
      data.append("message", messageRef.current!.value);

      // Return
      return data;
    }

    return null;
  };

  // This handles the form submmission
  const contactFormCallback = useCallback(async (event: Event) => {
    // Prevent form from refreshing the page
    event.preventDefault();

    // Triggers state change
    setValidated(true);

    // Get form data
    const data = createFormData();

    // Null check
    if (data === null) {
      return;
    }

    try {
      await fetch(`${process.env.PUBLIC_URL}/contact.php`, {
        method: "POST",
        body: data,
      }).then((data) => {
        if (data.status === 200) {
          toast.success("Beskeden er blevet sendt.", {
            draggable: false,
            theme: "dark",
            icon: true,
          });
        } else {
          toast.error("Beskeden kunne ikke blive sendt.", {
            draggable: false,
            theme: "dark",
            icon: true,
          });
          console.log(`Request failed with a status of ${data.status}.`);
        }
      });
    } catch (error) {
      console.log((error as Error).message);
    }

    // Resets the form input values
    resetForm();
  }, []);

  return (
    <>
      <Form
        className="form position-relative"
        id="contact-form"
        ref={formRef}
        validated={validated}
      >
        <header>
          <h1 className="text-center text-main my-2">
            <FormattedMessage id="header" />
          </h1>
          <p className="lead text-center my-4">
            <FormattedMessage id="description1" />
            <br />
            <FormattedMessage id="description2" />
          </p>
        </header>

        <Row className="mb-3">
          <Form.Group as={Col} xs={12} md={6} controlId="formGridEmail">
            <Form.Label className={`${styles.contactFormLabel} `}>
              <FormattedMessage id="firstname" />
            </Form.Label>
            <input
              ref={firstnameRef}
              className={styles.contactFormText}
              type="text"
              placeholder="Baek"
              required
            />
          </Form.Group>

          <Form.Group as={Col} xs={12} md={6} controlId="formGridPassword">
            <Form.Label className={styles.contactFormLabel}>
              <FormattedMessage id="lastname" />
            </Form.Label>
            <input
              ref={lastnameRef}
              name="lastname"
              className={styles.contactFormText}
              type="text"
              placeholder="Baeksen"
              required
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} xs={12} md={6} controlId="formGridEmail">
            <Form.Label className={styles.contactFormLabel}>
              <FormattedMessage id="email" />
            </Form.Label>
            <input
              ref={emailRef}
              name="email"
              className={styles.contactFormText}
              type="email"
              placeholder="Kontakt@baek.pro"
              required
            />
          </Form.Group>

          <Form.Group as={Col} xs={12} md={6} controlId="formPhoneNumber">
            <Form.Label className={styles.contactFormLabel}>
              <FormattedMessage id="phone" />
            </Form.Label>
            <input
              ref={phoneRef}
              name="phone"
              className={styles.contactFormText}
              type="tel"
              placeholder="+45 00000000"
            />
          </Form.Group>
        </Row>

        <Form.Group as={Col} controlId="formMessage">
          <Form.Label className={styles.contactFormLabel}>
            <FormattedMessage id="message" />
          </Form.Label>

          <FormattedMessage id="yourMessage">
            {(placeholder) => (
              <textarea
                ref={messageRef}
                name="message"
                className={styles.contactFormText}
                placeholder={placeholder.toString()}
                rows={3}
                required
                style={{ resize: "none" }}
              />
            )}
          </FormattedMessage>
        </Form.Group>

        <Row>
          <Form.Group as={Col} controlId="formMessage">
            <div className="my-3">
              <Captcha callback={contactFormCallback} formId="contact-form" />
            </div>
            <Button variant="success" type="submit">
              <FormattedMessage id="sendMessage" />
            </Button>
          </Form.Group>
          <p className="text-muted mt-2">
            <b>*</b> <FormattedMessage id="required" />
          </p>
        </Row>
      </Form>
    </>
  );
}
