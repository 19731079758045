import { LOCALES } from "../locales";

const deDE = {
  [LOCALES.GERMAN]: {
    header: "Kontaktiere Mich",
    description1: "Füllen Sie das untenstehende Formular mit Ihrer Frage aus,",
    description2: "und ich muss versuchen, so schnell wie möglich zurückgeben.",
    firstname: "Vorname *",
    lastname: "Nachname *",
    email: "Email *",
    phone: "Telefonnummer",
    message: "Nachricht *",
    yourMessage: "Ihre Nachricht",
    sendMessage: "Nachricht senden",
    required: "Diese Felder sind Pflichtfelder.",
  },
};

export default deDE;
