import { useEffect } from "react";

/**
 * Interface defining props for Captcha.
 */
interface CaptchaProps {
  /** Function which should be called by the event listener. */
  callback: (this: HTMLElement, event: Event) => any;
  /** Id of the Form element using the Captcha. */
  formId: string;
}

/**
 * Component used for easily loading reCAPTCHA v3.
 * @returns Captcha div element.
 */
export default function Captcha(props: CaptchaProps) {
  // Called when component is mounted
  useEffect(() => {
    // Create script
    const script = document.createElement("script");

    // Set script values
    script.src = `https://www.google.com/recaptcha/api.js`;
    script.async = true;
    script.defer = true;

    // Add to body
    document.body.appendChild(script);

    // Add event listener to contact form
    document
      .getElementById(props.formId)!
      .addEventListener("submit", props.callback);

    // Called when component is unmounted
    return () => {
      // Remove event listener from contact form
      document
        .getElementById(props.formId)!
        .removeEventListener("submit", props.callback);

      // Add to body
      document.body.removeChild(script);
    };
  }, [props.callback, props.formId]);

  // Return captcha box
  return (
    <div
      className="g-recaptcha"
      data-theme="dark"
      data-sitekey="6LfLxcsZAAAAAPRFMzSpCmppKAtFZf_A0FTQWhPD"
    />
  );
}
