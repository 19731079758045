import { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { I18NProvider, LOCALES } from "./i18n";

import Particles from "./components/Particles/Particles";
import Contact from "./pages/Contact/Contact";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App: React.FC = () => {
  const [locale, setLocale] = useState(LOCALES.ENGLISH);

  useEffect(() => {
    // Get locale from browser
    const browserLocale =
      navigator.languages === undefined
        ? [navigator.language][0]
        : navigator.languages[0];

    // Check locale
    if (browserLocale !== locale) {
      if (browserLocale.includes("da")) {
        setLocale(LOCALES.DANISH);
      } else if (browserLocale.includes("de")) {
        setLocale(LOCALES.GERMAN);
      }
    }
  }, [locale, setLocale]);

  return (
    <>
      <I18NProvider locale={locale}>
        <Particles />
        <section className="container-fluid vertical-center">
          <BrowserRouter>
            <Switch>
              <Route path="/" exact>
                <Contact />
              </Route>
              <Route
                path="/"
                component={() => {
                  window.location.href =
                    "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
                  return null;
                }}
              />
            </Switch>
          </BrowserRouter>
        </section>
        <ToastContainer />
      </I18NProvider>
    </>
  );
};

export default App;
