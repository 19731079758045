import daDK from "./da-DK";
import deDE from "./de-DE";
import enUS from "./en-US";

const index = {
  ...daDK,
  ...deDE,
  ...enUS,
};

export default index;
