import { LOCALES } from "../locales";

const enUS = {
  [LOCALES.ENGLISH]: {
    header: "Contact Me",
    description1: "Fill out the form below with your question,",
    description2: "and i'll try my best to respond as quickly as possible.",
    firstname: "Firstname *",
    lastname: "Lastname *",
    email: "Email *",
    phone: "Phone number",
    message: "Message *",
    yourMessage: "Your message",
    sendMessage: "Send message",
    required: "These fields are required.",
  },
};

export default enUS;
